import { mapActions, mapState } from 'vuex'

export default{
    
  data: () => ({
    collections: [],
    params: {},
    message: '',
    error: false,
    errors: {},
    loading: false,
    meta: {},
    itemDelete: {},
    model: {},
    show: false,
    text: 'Carregando dados...',
    loadingProgress: false,
    loadingRefresh: false,
    textDelete: '',
    deleting: false,
    onlyMyReports: false,
    headers: [
        { text: 'Relatório', value: 'report'},
        { text: 'Data', value: 'updated_at', align: 'center'},
        { text: 'Usuário', value: 'user.name', align: 'center'},
        { text: 'Status', value: 'status', with: '20px', align: 'center'},
        { text: '', value: 'actions'},
    ]
    }),

    computed: {
        ...mapState('auth', ['user', 'acesso']),

        reportsGrids(){

            let ids = [80,58];

            if(this.user.is_master){
                ids.push(50);
            }

            if(this.isAnima() && this.user.is_master){
                ids.push(83);
            }

            return ids;

        },

        reportsTimeTables(){

            let ids = [81,28,53];

            if(this.user.is_master){
                ids.push(12);
            }

            return ids;

        },

        reportsOthers(){
            
            let ids = [11,20];

            if(this.isAnima() && this.user.is_master){
                ids.push(100);
            }

            return ids;
        }
       
    },

    watch: {
      params(params_new, params_old){
        if(params_new != params_old) {
            this.getData();
        }
      },
       //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir o item?";
          } else {
              this.show = false
              this.textDelete = '';
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          
          if(show_new != show_old && !show_new) {
              this.itemDelete = {};
          }
      },

      onlyMyReports(val){
        this.getData();
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.errors = {};
          }           
      },

      
    },
    methods: {
      ...mapActions('user', ['ActionIndexUserDownloads', 'ActionDestroyUserDownload']),
        
      getData(refresh = false){
        
        this.error = false;
        this.message = '';
        this.loadingProgress = true;
        this.loadingRefresh = refresh;
        
        let payload = this.params;

        payload.with = 'status,user,report';
        payload.column_order = 'updated_at';
        payload.direction = 'desc';

        if(this.onlyMyReports){
            payload.find_columns = {
                user_id: this.user.id,
            };
        }else{
            delete payload.find_columns;
        }

        this.ActionIndexUserDownloads(payload)
            .then((res) => {
                this.collections = res.data;
                this.meta = res.meta;
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
                this.isCompleted = false;
                this.loadingRefresh = false;
            })
    },

    deleteItem()
    {
        this.deleting = true;
        this.message = '';

        this.ActionDestroyUserDownload({id: this.itemDelete.id})
            .then((res) => {
                this.message = res.message;
                this.show = false;
                this.getData();
            })
            .catch((error) =>{
                this.message = error.message;
                this.error = true;
            })
            .finally(() => {
                this.deleting = false;
            });
    },

    onCompleted(error, message){
        
        if(error){
            this.message = '';
            this.$nextTick(() => {
                this.message = message;
                this.error = error;
            });
        }else{
            this.getData();
        }
        
    },

  },

}
